
import { EventBus } from "@/plugins/eventBus"
import axios, { AxiosError, AxiosResponse } from "axios"
import Vue, { PropType } from "vue"
import DateTimePicker from "../DateTimePicker.vue"
import { format, addWeeks } from "date-fns"

export default Vue.extend({
  name: "UpdateAvailabilityDialog",
  components: {
    DateTimePicker,
  },
  props: {
    value: { // used to make v-model works
      type: Boolean,
      default: false
    },
    selectedSW: { // super_worker selection from Parangon
      type: Array as PropType<number[]>,
      required: true,
    },
    workersData: { // worker data passed from parent for formatting messages
      type: Array as PropType<any[]>,
      default: () => []
    }
  },
  data () {
    return {
      valid: false,
      loading: false,
      status: "",
      previousStatus: "",
      showStatusError: false,
      startDate: {dateObject: null as Date | null, dateString: "", timeString: ""},
      endDate: {dateObject: null as Date | null, dateString: "", timeString: ""},
      followUpDate: {dateObject: null as Date | null, dateString: "", timeString: ""},
      addFollowUp: true,
      comment: "",
      rules: {
        required: (v: any) => !!v || this.$vuetify.lang.t("$vuetify.required_field"),
      },
      selectedPositions: [] as number[]
    }
  },
  watch: {
    inputValue: function (val: boolean) {
      if (val === true) { // reset the component when it is shown
        this.init()
      }
    },
    status(newValue) {
      // Hide error when status is set
      if (newValue) {
        this.showStatusError = false;
      }
      
      // When status is contract, disable follow-up
      if (newValue === 'contract') {
        this.addFollowUp = false;
      } else if (this.previousStatus === 'contract' && newValue !== 'contract') {
        // When changing from contract to another status, re-enable follow-up
        this.addFollowUp = true;
      }
      
      // Store current status for future reference
      this.previousStatus = newValue;
      
      // Validate form
      this.validateForm();
    },
    addFollowUp() {
      // Re-validate form when follow-up toggle changes
      this.validateForm();
    },
    startDate: {
      deep: true,
      handler() {
        this.validateForm();
      }
    },
    endDate: {
      deep: true,
      handler() {
        this.validateForm();
      }
    },
    followUpDate: {
      deep: true,
      handler() {
        this.validateForm();
      }
    }
  },
  computed: {
    inputValue: { // bind value prop to our v-model on v-dialog
      get(): boolean {
        return this.value
      },
      set(val: boolean) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    /**
     * Validates the form and updates valid state
     */
    validateForm() {
      // Check status is selected
      if (!this.status) {
        this.valid = false;
        return;
      }
      
      // Check start date is set
      if (!this.startDate.dateObject) {
        this.valid = false;
        return;
      }
      
      // Check end date is set
      if (!this.endDate.dateObject) {
        this.valid = false;
        return;
      }
      
      // Check end date is after start date
      if (new Date(this.startDate.dateString + ' ' + this.startDate.timeString) >= 
          new Date(this.endDate.dateString + ' ' + this.endDate.timeString)) {
        this.valid = false;
        return;
      }
      
      // Check follow-up date is set when required
      if (this.addFollowUp && this.status !== 'contract' && !this.followUpDate.dateObject) {
        this.valid = false;
        return;
      }
      
      // If all checks pass, form is valid
      this.valid = true;
    },
    
    /**
     * Resets the component's state
     */
    init() {
      // display snackbar error and hide component if no super_worker in selection
      if (this.selectedSW.length === 0) {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.empty_selection'), color: 'error' })
        this.inputValue = false
        return
      }

      // Reset form values
      this.valid = false
      this.status = "available" // Default to available status
      this.previousStatus = "" // Reset previous status
      this.showStatusError = false
      this.comment = ""
      this.addFollowUp = true

      // Set default dates
      const now = new Date()
      const nextWeek = addWeeks(now, 1)
      
      // Set start date to now
      this.startDate = {
        dateObject: now,
        dateString: format(now, 'yyyy-MM-dd'),
        timeString: format(now, 'HH:mm:ss')
      }
      
      // Set end date to now + 1 week
      this.endDate = {
        dateObject: nextWeek,
        dateString: format(nextWeek, 'yyyy-MM-dd'),
        timeString: format(now, 'HH:mm:ss')
      }
      
      // Set follow up date to now + 1 week
      this.followUpDate = {
        dateObject: nextWeek,
        dateString: format(nextWeek, 'yyyy-MM-dd'),
        timeString: format(nextWeek, 'HH:mm')
      }
      
      // Validate form with initial values
      this.$nextTick(() => {
        this.validateForm();
      });
    },
    
    /**
     * Find worker data by ID
     */
    findWorkerData(workerId: string | number) {
      // Convert workerId to string for comparison if it's a number
      const idToFind = workerId.toString();
      
      // Find the worker with matching ID in the workersData
      return this.workersData.find(worker => worker.id.toString() === idToFind);
    },
    
    /**
     * Submit the form and register the availability
     */
    submit() {
      // Show error if status is not selected
      if (!this.status) {
        this.showStatusError = true;
        return;
      }
      
      this.loading = true;
      
      // Ensure time strings are properly formatted
      if (this.startDate.timeString.length < 8) {
        this.startDate.timeString = this.startDate.timeString + ':00'
      }
      
      if (this.endDate.timeString.length < 8) {
        this.endDate.timeString = this.endDate.timeString + ':00'
      }
      
      // Create request body
      const body: {
        id: number[],
        add_availability: {
          status: string,
          date_begin: string,
          date_end: string,
          comment?: string,
          date_follow_up?: string,
          author: string,
        }
      } = {
        id: this.selectedSW,
        add_availability: {
          status: this.status,
          date_begin: `${this.startDate.dateString} ${this.startDate.timeString}`,
          date_end: `${this.endDate.dateString} ${this.endDate.timeString}`,
          author: "agency"
        }
      }
      
      // Add comment if provided
      if (this.comment.trim()) {
        body.add_availability.comment = this.comment
      }
      
      // Add follow up date if enabled and not in contract status
      if (this.addFollowUp && this.status !== 'contract') {
        // Ensure follow-up time string is properly formatted
        let followUpTimeString = this.followUpDate.timeString;
        if (followUpTimeString.length < 8) {
          followUpTimeString = followUpTimeString + ':00'
        }
        body.add_availability.date_follow_up = `${this.followUpDate.dateString} ${followUpTimeString}`
      }
      
      // Send the request
      axios.post('/v1/workers/action', body)
        .then((response: AxiosResponse) => {
          // Close the dialog
          this.inputValue = false;
          
          // Check if there are any worker-specific messages in the response
          if (response.data && typeof response.data === 'object' && !Array.isArray(response.data)) {
            const entries = Object.entries(response.data);
            
            if (entries.length > 0) {
              // Convert to a structured message format with worker names
              const workerMessages = entries.map(([workerId, message]) => {
                const translatedMessage = this.$vuetify.lang.t(`$vuetify.${message}`);
                
                // Find worker data for this ID
                const workerData = this.findWorkerData(workerId);
                
                if (workerData) {
                  // Format with worker name and first name
                  return `${workerData.last_name.toUpperCase()} ${workerData.first_name}: ${translatedMessage}`;
                } else {
                  // Fallback to just the message
                  return translatedMessage;
                }
              });
              
              // Use the new structured message format
              EventBus.$emit("snackbar", { 
                color: 'warning',
                timeout: Math.min(5000 + (entries.length * 1000), 10000), // 5 seconds + 1 second per worker
                structuredMessage: {
                  title: this.$vuetify.lang.t("$vuetify.workers_in_error"),
                  items: workerMessages
                }
              });
            } else {
              // No specific messages, show general success message
              EventBus.$emit("snackbar", { 
                message: this.$vuetify.lang.t("$vuetify.success_register_availability") 
              });
            }
          } else {
            // Standard success message
            EventBus.$emit("snackbar", { 
              message: this.$vuetify.lang.t("$vuetify.success_register_availability") 
            });
          }
        })
        .catch((error: AxiosError) => {
          EventBus.$emit("snackbar", { axiosError: error });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
})
