
import Vue from "vue"
import WorkerList from "@/components/workers/WorkerList.vue"

export default Vue.extend({
    components: {
        WorkerList
    },
    data () {
      return {
        tab: 0,
        workerRefresh: 0,
        deletedWorkerRefresh: 0
      }
    },
    watch: {
      tab: {
        handler(newVal: number, oldVal: number) {
          if (newVal === 0) {
            this.workerRefresh++
          } else {
            this.deletedWorkerRefresh++
          }
        }
      }
    }
})
