import axios from 'axios'
import { VNodeDirective } from 'vue/types/vnode'

type DirectiveFn = (
  el: HTMLElement,
  binding: VNodeDirective,
  vnode: any,
  oldVnode: any
) => void

export const authPdfDirective = {
  bind: (async (el: HTMLElement, binding: VNodeDirective) => {
    const iframe = el as HTMLIFrameElement
    const token = localStorage.getItem('token')

    try {
      const response = await axios.get(binding.value, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      iframe.src = url + '#toolbar=0'
    } catch (error) {
      console.error('Error loading PDF:', error)
    }
  }) as DirectiveFn
} 