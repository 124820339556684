
import Vue, { PropType } from 'vue'
import axios, { CancelTokenSource } from 'axios'
import { EventBus } from '@/plugins/eventBus'
import { state } from '@/plugins/state'

interface Position {
  position_id: number;
  name: string;
}

export default Vue.extend({
  name: 'PositionAutocomplete',
  inheritAttrs: false,
  props: {
    value: {
      type: Array as PropType<Position[]>,
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      positions: [] as Position[],
      loading: false,
      loadingAll: false,
      loadedAll: false,
      search: null as string | null,
      selectAll: false,
      currentPage: 1,
      maxInitialPages: 8,
      currentRequest: null as CancelTokenSource | null,
      searchTimeout: null as number | null
    }
  },
  computed: {
    selectedAndPositions(): Position[] {
      const uniquePositions = new Map<number, Position>()
      
      // Add all current positions
      this.positions.forEach(position => {
        uniquePositions.set(position.position_id, position)
      })
      
      // Add all selected positions that aren't in current positions
      this.value?.forEach(position => {
        if (!uniquePositions.has(position.position_id)) {
          uniquePositions.set(position.position_id, position)
        }
      })
      
      return Array.from(uniquePositions.values())
    },
    selectAllIcon(): string {
      const allSelected = this.positions.every(position => 
        this.value?.some(v => v.position_id === position.position_id)
      )
      const someSelected = this.positions.some(position =>
        this.value?.some(v => v.position_id === position.position_id)
      )

      if (!someSelected) {
        return 'mdi-checkbox-blank-outline'
      } else if (allSelected) {
        return 'mdi-close-box'
      } else {
        return 'mdi-minus-box'
      }
    }
  },
  watch: {
    search(val: string | null) {
      if (val === null) return
      
      // Cancel previous timeout if it exists
      if (this.searchTimeout) {
        window.clearTimeout(this.searchTimeout)
      }

      // Debounce search to avoid too many requests
      this.searchTimeout = window.setTimeout(() => {
        this.searchPositions(val)
      }, 300)
    }
  },
  beforeDestroy() {
    this.cancelCurrentRequest()
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout)
    }
  },
  methods: {
    filterOverride() {
      // Disable default filtering since we handle it through API
      return true
    },
    resetPositions() {
      this.positions = []
      this.loadedAll = false
      this.currentPage = 1
      this.search = null
      this.loadInitialPages()
    },
    cancelCurrentRequest() {
      if (this.currentRequest) {
        this.currentRequest.cancel('Operation canceled due to new request.')
        this.currentRequest = null
      }
    },
    async loadPositions(search?: string, maxPage = 1): Promise<{hasMore: boolean; lastPage: number | null}> {
      try {
        this.loading = true
        
        // Only cancel ongoing requests during search operations
        if (search) {
          this.cancelCurrentRequest()
          this.currentRequest = axios.CancelToken.source()
        }

        // Load first page
        const params: {[key: string]: string | number} = { page: 1 }
        if (search) {
          params.name = search
        }
        if (state.agency) {
          params.space_id = state.agency
        }

        const response = await axios.get('/v1/clients/positions', { 
          params,
          ...(search ? { cancelToken: this.currentRequest?.token } : {})
        })
        
        // Check if we have more pages based on Link header
        const linkHeader = response.headers.link
        const lastPageLink = linkHeader?.match(/page=(\d+)[^>]*>;\s*rel="last"/)
        const lastPage = lastPageLink ? parseInt(lastPageLink[1]) : null
        
        // Initialize positions array with first page
        let allPositions = [...response.data]

        // Calculate how many additional pages to load
        if (lastPage && lastPage > 1 && maxPage > 1) {
          const pagesToLoad = Math.min(lastPage, maxPage)
          
          if (pagesToLoad > 10) {
            // Load pages in batches of 10
            const remainingPages = Array.from(
              { length: pagesToLoad - 1 },
              (_, i) => i + 2
            )

            // Split remaining pages into chunks of 10
            const chunks = []
            for (let i = 0; i < remainingPages.length; i += 10) {
              chunks.push(remainingPages.slice(i, i + 10))
            }

            // Process chunks with delay between each
            for (const chunk of chunks) {
              const chunkResponses = await Promise.all(
                chunk.map(page => 
                  axios.get('/v1/clients/positions', {
                    params: { ...params, page },
                    ...(search ? { cancelToken: this.currentRequest?.token } : {})
                  })
                )
              )
              
              // Add positions from this chunk
              chunkResponses.forEach(resp => {
                allPositions = [...allPositions, ...resp.data]
              })

              // Sleep between chunks
              if (chunk !== chunks[chunks.length - 1]) {
                await new Promise(resolve => setTimeout(resolve, 100))
              }
            }
          } else {
            // For small number of pages, load all at once
            const remainingPages = Array.from(
              { length: pagesToLoad - 1 },
              (_, i) => i + 2
            )

            const additionalResponses = await Promise.all(
              remainingPages.map(page => 
                axios.get('/v1/clients/positions', {
                  params: { ...params, page },
                  ...(search ? { cancelToken: this.currentRequest?.token } : {})
                })
              )
            )
            
            additionalResponses.forEach(resp => {
              allPositions = [...allPositions, ...resp.data]
            })
          }

          this.currentPage = pagesToLoad
        } else {
          this.currentPage = 1
        }

        // Set positions once at the end
        this.positions = allPositions

        const hasMore = Boolean(lastPage && this.currentPage < lastPage)
        return { hasMore, lastPage }
      } catch (error) {
        console.error('Error in loadPositions:', error)
        if (!axios.isCancel(error)) {
          EventBus.$emit('snackbar', { axiosError: error })
        }
        return { hasMore: false, lastPage: null }
      } finally {
        this.loading = false
        if (search) {
          this.currentRequest = null
        }
      }
    },
    async searchPositions(search: string) {
      this.loadedAll = false
      const { hasMore } = await this.loadPositions(search, this.maxInitialPages)
      this.loadedAll = !hasMore
    },
    async loadInitialPages() {
      const { hasMore, lastPage } = await this.loadPositions(undefined, this.maxInitialPages)
      this.loadedAll = !hasMore || (lastPage ? this.currentPage >= lastPage : false)
    },
    async loadAllPositions() {
      this.loadingAll = true
      try {
        await this.loadPositions(this.search || undefined, 99999)
        this.loadedAll = true
      } finally {
        this.loadingAll = false
      }
    },
    toggleSelectAll() {
      // Check if all visible positions are already selected
      const allVisibleSelected = this.positions.every(position => 
        this.value?.some(selected => selected.position_id === position.position_id)
      )

      let newSelection: Position[]
      if (allVisibleSelected) {
        // If all visible positions are selected, remove them
        newSelection = this.value?.filter(selected => 
          !this.positions.some(position => position.position_id === selected.position_id)
        ) ?? []
        this.selectAll = false
      } else {
        // Add any visible positions that aren't already selected
        // Ensure we start with an array, even if this.value is null/undefined
        newSelection = [...(this.value ?? [])]
        for (const position of this.positions) {
          if (!this.value?.some(selected => selected.position_id === position.position_id)) {
            newSelection.push(position)
          }
        }
        this.selectAll = true
      }

      this.$emit('input', newSelection)
    }
  },
  async created() {
    await this.loadInitialPages()
  }
})
