
import Vue, { VueConstructor } from "vue"
import ListParagon from "@/components/ListParagon.vue"
import { state } from "@/plugins/state"
import { EventBus } from "@/plugins/eventBus"
import dateFormatMixin from "@/mixins/DateFormatMixin.vue"
import ClientsFormatter from "@/components/workers/ClientsFormatter.vue"
import DateTimePicker from '@/components/DateTimePicker.vue'
import PositionsAutocompleteMixin from "@/mixins/PositionsAutocompleteMixin.vue"
import format from "date-fns/format"
import SendCVDialog from "@/components/workers/SendCVDialog.vue"
import SendSMSDialog from "@/components/workers/SendSMSDialog.vue"
import UpdateAvailabilityDialog from "@/components/workers/UpdateAvailabilityDialog.vue"
import { DataTableHeader } from 'vuetify'
import axios from "axios"
import { authPdfDirective } from '@/directives/authPdf'
import { DirectiveBinding } from 'vue/types/options'
import PositionAutocomplete from '@/components/PositionAutocomplete.vue'

interface Position {
  position_id: number;
  name: string;
}

interface Comment {
  id: number
  text: string
  user_first_name: string
  user_last_name: string
  created_at: string
  updated_at: string
}

interface EditedComment {
  id: number | null
  text: string
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof dateFormatMixin> & InstanceType<typeof PositionsAutocompleteMixin>>).extend({
  name: "WorkerList",
  mixins: [
    dateFormatMixin,
    PositionsAutocompleteMixin,
  ],
  props: {
    deleted: {
      type: Boolean,
      default: false
    },
    refresh: {
      type: Number,
      default: 0
    }
  },
  components: {
    ListParagon,
    ClientsFormatter,
    DateTimePicker,
    SendCVDialog,
    SendSMSDialog,
    PositionAutocomplete,
    UpdateAvailabilityDialog
  },
  data () {
    return {
      view: "list",
      dialog_comment: false,
      disabledSubmit: false,
      adaptTimeData: ':00',
      totalWorkers: 0,
      // show modal
      showSendCV: false,
      showSendSMS: false,
      showUpdateAvailability: false,
      availabilityRequestDate: {dateObject: new Date(), dateString: "", timeString: ""},
      dialog: false,
      titleModal: "alerts_details",
      major: true,
      data: null,
      textAreaData: "",
      selectedPositions: null as Position[] | null,
      states: [
        {"value":"web", name: this.$vuetify.lang.t('$vuetify.worker_states.web')},
        {"value":"candidate", name: this.$vuetify.lang.t('$vuetify.worker_states.candidate')},
        {"value":"employee", name: this.$vuetify.lang.t('$vuetify.worker_states.employee')},
        {"value":"applicant", name: this.$vuetify.lang.t('$vuetify.worker_states.applicant')},
        {"value":"pre-registered", name: this.$vuetify.lang.t('$vuetify.worker_states.pre_registered')},
        {"value":"supplements OK", name: this.$vuetify.lang.t('$vuetify.worker_states.supplement_ok')},
      ],
      updatableProperties: {
        state: null,
        add_comment: null,
        add_positions: null,
        remove_positions: null,
        deleted_at: null,
        availability_request: null,
      } as { [key: string]: any },
      propertiesToUpdate: null as null|{
        url: string,
        success: string,
        data: {[key: string]: string | number},
        action: string
      },
      openModal: false,
      modalName: "add_comment",
      selected: [] as string[],
      workerTranslation: 'worker',
      headers: [
        {
            text: this.$vuetify.lang.t("$vuetify.card"),
            sortable: true,
            value: 'card'
        },
        {
            text: this.$vuetify.lang.t("$vuetify.favorite_jobs"),
            sortable: true,
            value: 'favorite_jobs',
            sortText: this.$vuetify.lang.t("$vuetify.favorite_jobs_total")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.availabilities"),
            sortable: true,
            value: 'status_updated_at',
            sortText: this.$vuetify.lang.t("$vuetify.status_updated_at")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.recruitment"),
            sortable: true,
            value:'follow_up',
            sortText: this.$vuetify.lang.t("$vuetify.recruitment_total")

        },
        {
            text: this.$vuetify.lang.t("$vuetify.SMS_interaction"),
            sortable: true,
            value: "sms_history",
            width: 250,
            sortText: this.$vuetify.lang.t("$vuetify.SMS_interaction_last_date")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.comments"),
            sortable: true,
            value: "last_comment",
            sortText: this.$vuetify.lang.t("$vuetify.comment_last_date")

        },
      ],
      headersSplitPane: [
        {
          text: this.$vuetify.lang.t("$vuetify.names"),
          sortable: true,
          value: 'names'
        }
      ],
      filters: [
        {label: 'searches', type: 'text-field'},
        {label: 'names', type: 'text-field'},
        {label: 'email', type: 'text-field'},
        {label: 'mobile_phone_number', type: 'text-field'},
        {
          label: 'state',
          type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.web"), value: "web"},
            {text: this.$vuetify.lang.t("$vuetify.candidate"), value: "candidate"},
            {text: this.$vuetify.lang.t("$vuetify.employee"), value: "employee"},
            {text: this.$vuetify.lang.t("$vuetify.applicant"), value: "applicant"},
            {text: this.$vuetify.lang.t("$vuetify.pre-registered"), value: "pre-registered"},
            {text: this.$vuetify.lang.t("$vuetify.supplements OK"), value: "supplements OK"}
          ],
          multiple: true
        },
        {
          label: 'status',
          text: this.$vuetify.lang.t("$vuetify.availabilities"),
          type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.unavailable"), value: "unavailable"},
            {text: this.$vuetify.lang.t("$vuetify.pending"), value: "pending"},
            {text: this.$vuetify.lang.t("$vuetify.available"), value: "available"},
            {text: this.$vuetify.lang.t("$vuetify.contract"), value: "contract"},
            {text: this.$vuetify.lang.t("$vuetify.unknown"), value: "unknown"}
          ],
          multiple: true
        },
        {label: 'positions', prependValue: 'true:', type: 'autocomplete', url:'/v1/clients/positions', text: 'name', value: 'position_id', search: 'name'},
        {label: 'favorite_jobs', type: 'text-field'},
        {label: 'postal_city_code', type: 'text-field'},
        {
          label: 'transportation_means',
          type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.car"), value: "car"},
            {text: this.$vuetify.lang.t("$vuetify.motorbike"), value: "motorbike"},
            {text: this.$vuetify.lang.t("$vuetify.bike_scooter"), value: "bike_scooter"},
            {text: this.$vuetify.lang.t("$vuetify.mass_transit"), value: "mass_transit"},
            {text: this.$vuetify.lang.t("$vuetify.moped"), value: "moped"},
            {text: this.$vuetify.lang.t("$vuetify.walk"), value: "walk"},
            {text: this.$vuetify.lang.t("$vuetify.carpool"), value: "carpool"}
          ],
          multiple: true
        },
        {label: 'comment', type: 'text-field'},
        {
          label:'cv',
          type: 'toggle',
          items: [
            {text: this.$vuetify.lang.t("$vuetify.with"), value: true},
            {text: this.$vuetify.lang.t("$vuetify.without"), value: false}
          ]
        },
        {label: 'formation_certificate', type: 'text-field'},
        {label: 'equipment', type: 'text-field'},
        {
          label: 'medical_examination',
          type: 'boolean-text-field',
          items: [
            {text: this.$vuetify.lang.t("$vuetify.future"), value: true},
            {text: this.$vuetify.lang.t("$vuetify.passed"), value: false},
          ]
        },
        {
          label: 'is_sms_response',
          type: 'toggle',
          items: [
            {text: this.$vuetify.lang.t("$vuetify.with"), value: true},
            {text: this.$vuetify.lang.t("$vuetify.without"), value: false}
          ]
        },
        {label: 'sms_response', type: 'text-field'},
        {label: 'sms_send', type: 'text-field'},
        {
          label: 'driver_licence',
          type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.A1"), value: "A1"},
            {text: this.$vuetify.lang.t("$vuetify.A progressif"), value: "A progressif"},
            {text: this.$vuetify.lang.t("$vuetify.A"), value: "A"},
            {text: this.$vuetify.lang.t("$vuetify.A direct"), value: "A direct"},
            {text: this.$vuetify.lang.t("$vuetify.B"), value: "B"},
            {text: this.$vuetify.lang.t("$vuetify.B1"), value: "B1"},
            {text: this.$vuetify.lang.t("$vuetify.C"), value: "C"},
            {text: this.$vuetify.lang.t("$vuetify.D"), value: "D"},
            {text: this.$vuetify.lang.t("$vuetify.E(B)"), value: "E(B)"},
            {text: this.$vuetify.lang.t("$vuetify.E(C)"), value: "E(C)"},
            {text: this.$vuetify.lang.t("$vuetify.E(D)"), value: "E(D)"}
          ],
          multiple: true
        },
        {
          label: 'has_phone',
          type: 'toggle',
          items: [
            {text: this.$vuetify.lang.t("$vuetify.with"), value: true},
            {text: this.$vuetify.lang.t("$vuetify.without"), value: false}
          ]
        },
        {
          label: 'sex',
          type: 'toggle',
          items: [
            {text: this.$vuetify.lang.t("$vuetify.mister"), value: "M"},
            {text: this.$vuetify.lang.t("$vuetify.misses"), value: "F"}
          ]
        }
      ],
      commentHeaders: [
        {
          text: this.$vuetify.lang.t('$vuetify.comment'),
          align: 'start',
          value: 'text',
          sortable: true,
          filterable: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.user_name'),
          value: 'user',
          sortable: true,
          filterable: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.created_at'),
          value: 'created_at',
          sortable: true,
          filterable: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.updated_at'),
          value: 'updated_at',
          sortable: true,
          filterable: true
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '50'
        }
      ] as DataTableHeader[],
      comments: [] as Comment[],
      loading: false,
      currentPage: 1,
      itemsPerPage: 5,
      totalComments: 0,
      currentWorkerId: null as number | null,
      sortBy: ['updated_at'],
      sortDesc: [true],
      search: '',
      deleteLoading: false,
      dialogConfirm: false,
      commentToDelete: null as number | null,
      dialogEdit: false,
      updateLoading: false,
      editedComment: {
        id: null,
        text: ''
      } as EditedComment,
      previewUrls: {} as { [key: string]: string },
      activePreviewId: null as number | null,
      hideTimeout: null as number | null,
      imageTypes: {} as { [key: string]: boolean },
      unsupportedFormat: {} as { [key: string]: boolean },
    }
  },
  computed: {
    url: function(): string {
      let agencyParameter = ""
      if (state.agency !== null) {
        agencyParameter = `&agency_id=${state.agency}`
      }
      return `/v1/workers?refresh=${this.refresh}&deleted_at=${this.deleted ? 'true' : 'false'}${agencyParameter}&fields=birth_date,clients,cv,date_last_comment,email,favorite_jobs,first_name,id,last_comment,last_name,mobile_phone_number,postal_city,postal_code,profile_picture,sms_history,state,status,status_updated_at,transportation_means`
    },
    filteredComments(): Comment[] {
      if (!this.search) return this.comments

      const searchLower = this.search.toLowerCase()
      return this.comments.filter(comment => {
        const userFullName = `${comment.user_last_name} ${comment.user_first_name}`.toLowerCase()
        const text = comment.text.toLowerCase()
        const createdAt = this.localizeDate(comment.created_at).toLowerCase()
        const updatedAt = this.localizeDate(comment.updated_at).toLowerCase()

        return userFullName.includes(searchLower) ||
               text.includes(searchLower) ||
               createdAt.includes(searchLower) ||
               updatedAt.includes(searchLower)
      })
    },
    paginatedComments(): Comment[] {
      let sortedComments = [...this.filteredComments]

      // Apply sorting if a column is selected
      if (this.sortBy.length > 0) {
        const sortKey = this.sortBy[0]
        const isDesc = this.sortDesc[0]

        sortedComments.sort((a: Comment, b: Comment) => {
          let aValue = a[sortKey as keyof Comment]
          let bValue = b[sortKey as keyof Comment]

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return isDesc 
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue)
          }

          return isDesc 
            ? (bValue as number) - (aValue as number)
            : (aValue as number) - (bValue as number)
        })
      }

      // Apply pagination
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return sortedComments.slice(start, end)
    }
  },
  watch: {
    availabilityRequestDate() {
      this.updatableProperties.availability_request = `${this.availabilityRequestDate.dateString} ${this.availabilityRequestDate.timeString}${this.adaptTimeData}`
      this.buildUpdateData(
        '/v1/workers/action',
        'availability_request_created',
        "post"
      )
    },
    async openModal() {
      // Start - BUGFIX
      this.updatableProperties = { //  reset API calls potential data
        state: null,
        add_comment: null,
        add_positions: null,
        remove_positions: null,
        deleted_at: null,
        availability_request: null,
        send_sms: null,
        send_cv: null
      }
      // Reset PositionAutocomplete when reopening modal
      if (this.modalName === 'add_positions' || this.modalName === 'remove_positions') {
        this.selectedPositions = null
        // Reset the positions data in PositionAutocomplete
        this.$nextTick(() => {
          (this.$refs.positionAutocomplete as any)?.resetPositions()
        })
      }
      // End - BUGFIX reset API calls potential data
      this.propertiesToUpdate = {url: "", success: "", data: {}, action: 'put'}
      this.totalWorkers = this.selected.length
      this.resetData()
    },
    selectedPositions() {
      this.updatableProperties[this.modalName] = this.selectedPositions?.map(p => p.position_id) || []
      // with an @change on the autocomplete, the data sent to ListParagon was always one value behind
      // since this data is already watched, I've put the buildUpdateData here
      this.buildUpdateData(
        '/v1/workers',
        `success_${this.modalName}`,
        'put'
      )
    },
    textAreaData() {
      this.updatableProperties[this.modalName] = this.textAreaData
    },
    updatableProperties: {
      handler() {
        let property  = this.modalName
        if (this.updatableProperties[this.labelBuilder()]) {
          property = this.labelBuilder()
        }
        if (this.updatableProperties[property] !== null && this.updatableProperties[property] !== undefined) {
          this.checkDisableSubmit(property)
        }
      },
      deep: true
    },
    dialog_comment(newValue: boolean) {
      if (newValue && this.currentWorkerId) {
        this.getComments(this.currentWorkerId)
      } else {
        this.comments = []
        this.totalComments = 0
        this.currentWorkerId = null
        this.currentPage = 1
        this.search = ''
      }
    },
    search() {
      this.currentPage = 1
    },
    dialogEdit(val) {
      if (!val) {
        this.resetEditedComment()
      }
    }
  },
  methods: {
    redirectToSplit(id: number) {
      (this.$refs.list as InstanceType<typeof ListParagon>).redirectToSplitPane(id)
    },
    resetData() {
      this.propertiesToUpdate = {url: "", success: "", data: {}, action: 'put'}
      this.textAreaData =''
      let date = new Date()
      switch (this.modalName) {
        case "archive":
          this.updatableProperties.deleted_at = format(date, "yyyy-MM-dd HH:mm:ss")
          this.buildUpdateData(
            '/v1/workers',
            this.totalWorkers > 1 ? 'success_archives' : 'success_archive',
            'put'
          )
          break
        case "reactivate":
          this.updatableProperties.deleted_at = null
          this.buildUpdateData(
            '/v1/workers',
            this.totalWorkers > 1 ? 'success_reactivates' : 'success_reactivate',
            'put'
          )
          break
        case "availability_request":
          this.updatableProperties[this.modalName] = format(date, "yyyy-MM-dd HH:mm:ss")
          this.buildUpdateData(
          '/v1/workers/action',
          'availability_request_created',
          "post"
          )
          break;
        case "add_positions":
        case "remove_positions":
          Vue.set(this.updatableProperties, this.modalName, null)
          this.selectedPositions = null
          this.buildUpdateData(
            '/v1/workers',
            `success_${this.modalName}`,
            'put'
          )
          break
        default:
          break
      }
    },
    checkDisableSubmit(property: string){
      this.disabledSubmit = this.updatableProperties[property].length === 0
    },
    redirectTo(name: string) {
      EventBus.$emit("availabilityWorker", name)
    },
    openData(titleModal: string, data: any, major = true) {
      this.dialog = true
      this.major = major
      this.data = data
      this.titleModal = titleModal
    },
    checkLogo(name: string): string {
      switch (name) {
        case 'bike_scooter':
          return 'bike'
        case 'mass_transit':
          return 'bus'
        case 'carpool':
          return 'car-2-plus'
        default:
          return name
      }
    },
    isMajor(date: string | undefined) {
      if (typeof date === 'undefined') {
        return true
      }
      return (Date.now() - new Date(date).getTime()) / 1000/60/60/24/365.25 >= 18
    },
    checkAlerts(alerts: Array<string>|undefined) {
      return alerts === undefined || alerts.length === 0
    },
    buildUpdateData(url: string, successText: string, action: string) {
      let body :{[key: string]: any} = {}
      if (action === "put") {
        body = this.buildPutData(body)
      } else if (action === "post") {
        body = this.buildPostData(body)
      }
      this.propertiesToUpdate = {url: url, success: successText, data: body, action: action}
    },
    buildPostData(body: {[key: string]: any}) {
      for (const [key, value] of Object.entries(this.updatableProperties)) {
        if (value) {
          body[key] = value
        }
      }
      body["id"] = this.selected
      return body
    },
    buildPutData(body: {[key: string]: any}) {
      for (const swId of this.selected) {
        for (const [key, value] of Object.entries(this.updatableProperties)) {
          if (value || (key === 'deleted_at' && this.modalName === 'reactivate')) { // allow deleted_at to be null when reactivating workers
            body[swId] = {
              [key]: value
            }
          }
        }
      }
      return body
    },
    getNbSelected(n: string[]) {
      this.selected = n
      if (this.selected.length > 1) {
        this.workerTranslation = 'workers'
      } else {
        this.workerTranslation = 'worker'
      }
    },
    resetModalFields() {
      if (['archive', 'reactivate'].includes(this.modalName)) {
        // empty selection
        (this.$refs.list as InstanceType<typeof ListParagon>).emptySelection()
        // trigger a refresh of the list
        this.$emit('update:refresh', this.refresh + 1)
      }
    },
    labelBuilder() {
      //return second part of splitted string like update_state => state
      return this.modalName.split('_')[1]
    },
    async getComments(workerId: number) {
      this.loading = true
      let allComments: Comment[] = []
      let page = 1
      let hasMorePages = true

      try {
        while (hasMorePages) {
          const response = await axios.get(`/v1/workers/${workerId}/comments?page=${page}`)
          
          allComments = [...allComments, ...response.data]
          
          const linkHeader = response.headers.link as string | undefined
          hasMorePages = Boolean(linkHeader && linkHeader.includes('rel="next"'))
          page++
        }

        this.comments = allComments
        this.totalComments = allComments.length
      } catch (error) {
        EventBus.$emit('snackbar', { axiosError: error })
      } finally {
        this.loading = false
      }
    },
    showComments(workerId: number) {
      this.currentWorkerId = workerId
      this.currentPage = 1
      this.dialog_comment = true
    },
    updateSort(value: string[]) {
      this.sortBy = value
    },
    updateSortDesc(value: boolean[]) {
      this.sortDesc = value
    },
    async deleteComment(commentId: number) {
      try {
        this.deleteLoading = true
        await axios.delete(`/v1/workers/${this.currentWorkerId}/comments/${commentId}`)
        
        // Recharger les commentaires
        await this.refreshComments()
        
        EventBus.$emit('snackbar', {
          message: this.$vuetify.lang.t('$vuetify.comment_deleted_successfully'),
          color: 'success'
        })

        this.dialogConfirm = false
      } catch (error) {
        EventBus.$emit('snackbar', { axiosError: error })
      } finally {
        this.deleteLoading = false
        this.commentToDelete = null
      }
    },
    confirmDelete(commentId: number) {
      this.commentToDelete = commentId
      this.dialogConfirm = true
    },
    handleConfirmDelete() {
      if (this.commentToDelete !== null) {
        this.deleteComment(this.commentToDelete)
      }
    },
    editComment(comment: Comment) {
      this.editedComment = {
        id: comment.id,
        text: comment.text
      }
      this.dialogEdit = true
    },
    async updateComment() {
      if (!this.editedComment.text.trim()) {
        EventBus.$emit('snackbar', {
          message: this.$vuetify.lang.t('$vuetify.comment_required'),
          color: 'error'
        })
        return
      }

      try {
        this.updateLoading = true
        await axios.put(
          `/v1/workers/${this.currentWorkerId}/comments/${this.editedComment.id}`,
          { text: this.editedComment.text.trim() }
        )

        // Reload comments
        await this.refreshComments()

        EventBus.$emit('snackbar', {
          message: this.$vuetify.lang.t('$vuetify.comment_updated_successfully'),
          color: 'success'
        })

        this.dialogEdit = false
      } catch (error) {
        EventBus.$emit('snackbar', { axiosError: error })
      } finally {
        this.updateLoading = false
      }
    },
    resetEditedComment() {
      this.editedComment = {
        id: null,
        text: ''
      }
    },
    async refreshComments() {
      if (this.currentWorkerId) {
        await this.getComments(this.currentWorkerId)
      }
      // Use EventBus to reload last_comment
      EventBus.$emit('listParagonDataReload')
    },
    openTo(email: string, type: string): void {
      if (email) {
        window.location.href = `${type}:${email}`
      }
    },
    getFileExtension(contentDisposition: string): string {
      const filename = contentDisposition.split('filename="')[1]?.split('"')[0]
      if (!filename?.includes('.')) return ''
      return filename.split('.').pop()?.toLowerCase() || ''
    },
    getMimeTypeFromExtension(extension: string): { mimeType: string; isImage: boolean } {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']
      
      if (imageExtensions.includes(extension)) {
        const imageType = extension === 'jpg' ? 'jpeg' : extension
        return { mimeType: `image/${imageType}`, isImage: true }
      }
      
      if (extension === 'pdf') {
        return { mimeType: 'application/pdf', isImage: false }
      }
      
      return { mimeType: '', isImage: false }
    },
    async fetchDocument(url: string): Promise<{ url: string; isImage: boolean }> {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get(url, {
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': '*/*'
          }
        })

        const contentDisposition = response.headers['content-disposition']
        if (!contentDisposition) {
          return { url: '', isImage: false }
        }

        const extension = this.getFileExtension(contentDisposition)
        const { mimeType, isImage } = this.getMimeTypeFromExtension(extension)

        if (!mimeType) {
          this.unsupportedFormat[url] = true
          return { url: '', isImage: false }
        }

        const blob = new Blob([response.data], { type: mimeType })
        return {
          url: URL.createObjectURL(blob),
          isImage
        }
      } catch (error) {
        console.error('Error fetching document:', error)
        EventBus.$emit('snackbar', { axiosError: error })
        return { url: '', isImage: false }
      }
    },
    async showPreview(cvUrl: string, workerId: number) {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
        this.hideTimeout = null
      }
      
      if (cvUrl && !this.previewUrls[cvUrl]) {
        try {
          const result = await this.fetchDocument(cvUrl)
          this.previewUrls[cvUrl] = result.url
          this.imageTypes[cvUrl] = result.isImage
        } catch (error) {
          console.error('Error fetching document:', error)
        }
      }
      
      this.activePreviewId = workerId
    },
    startHidePreview() {
      this.hideTimeout = window.setTimeout(this.hidePreview, 100)
    },
    cancelHidePreview() {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
        this.hideTimeout = null
      }
    },
    hidePreview() {
      this.activePreviewId = null
    },
    isImage(url: string): boolean {
      return this.imageTypes[url] || false
    },
    handlePositionSelection(positions: Position[]) {
      this.updatableProperties[this.modalName] = positions.map(p => p.position_id)
      this.buildUpdateData(
        '/v1/workers',
        `success_${this.modalName}`,
        'put'
      )
    },
    getSelectedWorkersData() {
      // Get the data for all selected worker IDs from the list component
      if (!this.$refs.list || !this.selected.length) return [];
      
      // Access items from the ListParagon component instead of data property
      const listData = (this.$refs.list as InstanceType<typeof ListParagon>).items || [];
      
      // Filter to only include selected workers
      return listData.filter(worker => this.selected.includes(worker.id.toString()));
    },
  },
  directives: {
    'auth-pdf': authPdfDirective as {
      bind: (el: HTMLElement, binding: DirectiveBinding) => void;
    }
  },
})

